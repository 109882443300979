import getDateUTC from '@/utils/dateTime/getDateUTC';

function format(dateString, hours = '00:00:00') {
  const date = getDateUTC(dateString);
  return date.toJSON().replace(/T\d{2}:\d{2}:\d{2}/, `T${hours}`);
}

export default function ({ range_start, range_end }) {
  return {
    range_start: format(range_start, '00:00:00'),
    range_end: format(range_end, '23:59:59'),
  };
}
