import { api } from '.';
import { PRODUCT_METRICS_RESOURCE, PRODUCT_RESOURCE } from '@/services/keys';

/**
 * @param {Product} product
 * @return {Promise.<Object>}
 */
export async function updateProductMetrics(product) {
  const partialUrl = api.createUrl({
    [PRODUCT_RESOURCE]: product.id,
    [PRODUCT_METRICS_RESOURCE]: undefined,
  });
  const { data } = await api.update(partialUrl, product);
  return data;
}
