import { thirdParty } from '..';
import { getProducts } from './getProducts';
import { getProductByCampaignGroup } from './getProductByCampaignGroup';
import { updateProductMetrics } from './updateProductMetrics';
import { getSalesforceProduct } from './getSalesforceProduct';
import { getSalesforceProductList } from './getSalesforceProductList';
import updateProductViewabilityIAS from './updateProductViewabilityIAS';

export const api = thirdParty;

export {
  getProducts,
  updateProductMetrics,
  getProductByCampaignGroup,
  updateProductViewabilityIAS,
  getSalesforceProduct,
  getSalesforceProductList,
};
