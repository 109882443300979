import ThirdPartyEntityParser from '@/entities/ThirdParty/ThirdPartyEntityParser';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import { PRODUCT_RESOURCE } from '@/services/keys';
import { api } from '.';

export const getResources = () => ({
  [PRODUCT_RESOURCE]: undefined,
});

/**
 *
 * @param {QueryParamsBuilder} params
 * @returns {Promise.<{data:Salesforce[], meta:import('@/model/shared/ResponseBuilder').EndpointMeta}>}
 */
export async function getSalesforceProductList(params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl(getResources());
  params.addInclude(['advertiser', 'opportunity']);
  const { data } = await api.get(partialUrl, params?.build?.() || params);
  return new ResponseBuilder(ThirdPartyEntityParser.parse(data), data.meta).build();
}
