import { api } from '.';
import { PRODUCT_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import formatDateFiltersToUTC from '@/utils/formatDateFiltersToUTC';

/**
 *
 * @param {CampaignGroup} campaignGroup
 * @param {QueryParamsBuilder} params
 * @returns {Promise.<Array>}
 */
export async function getProductByCampaignGroup(campaignGroup, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl({
    [PRODUCT_RESOURCE]: undefined,
  });

  params.addFilter('product.campaignGroup.id', campaignGroup);
  params.addInclude(['advertiser', 'platform', 'platform.provider']);

  params.filters = {
    ...params.filters,
    ...formatDateFiltersToUTC(params.filters),
  };

  const {
    data: { data, included },
  } = await api.get(partialUrl, params?.build ? params.build() : params);

  return transformToTableItems(data, included);
}

function transformToTableItems(data, included) {
  return data.map(({ id, attributes, relationships }) => {
    return {
      id,
      advertiser: getAdvertiser(relationships, included),
      platform: getPlatform(relationships, included),
      ...attributes,
    };
  });
}

function getAdvertiser(relation, included) {
  if (relation?.advertiser?.data) {
    const { id, attributes } = included.find(
      item => item.type === 'advertisers' && item.id === relation.advertiser.data.id
    );
    return { id, ...attributes };
  }
}

function getPlatform(relation, included) {
  if (relation?.platform?.data) {
    const { id, attributes } = included.find(
      item => item.type === 'platforms' && item.id === relation.platform.data.id
    );

    const providerAttrs = included.find(
      item => item.type === 'providers' && item.id === attributes?.providerId
    )?.attributes;

    return { id, ...attributes, provider: providerAttrs?.name };
  }
}
