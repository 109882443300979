import { api } from '.';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { CLIENT_RESOURCE, PRODUCT_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import ThirdPartyEntityParser from '@/entities/ThirdParty/ThirdPartyEntityParser';

export const getResources = clientId => ({
  [PRODUCT_RESOURCE]: undefined,
  [CLIENT_RESOURCE]: clientId,
});

/**
 *
 * @param {string} clientId
 * @param {QueryParamsBuilder} params
 * @returns {Promise.<{data: object[],meta:EndpointMeta}>}
 */
export async function getProducts(clientId, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl(getResources(clientId));
  params.addInclude(['advertiser', 'client']);

  const { data } = await api.get(partialUrl, params?.build ? params.build() : params);
  return new ResponseBuilder(ThirdPartyEntityParser.parse(data), data.meta).build();
}
