import { api } from '.';
import { PRODUCT_RESOURCE, IAS_RESOURCE } from '@/services/keys';
/**
 * @param {string} productId
 * @param {File} iasFile XLSX file
 * @return {Promise<>}
 */
export default async function (productId, iasFile) {
  const partialUrl = api.createUrl({
    [PRODUCT_RESOURCE]: productId,
    [IAS_RESOURCE]: null,
  });

  const bodyData = new FormData();
  bodyData.set('ias_file', iasFile);
  // hable con mik3lon, decidimos que back vera si tiene o no cabecera,
  // pero por ahora lo dejo en true
  bodyData.set('hasHeader', 1);

  const { data } = await api.create(partialUrl, bodyData);
  return data;
}
